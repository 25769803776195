<template>
  <router-link
    :to="`/${tile.name}/`"
    :style="`--aspect-ratio:${aspectRatio}`"
    :class="[tile.name, imageClass]"
    class="content-tile"
  >
    <img
      v-if="hasImage"
      :width="imageWidth"
      :height="imageHeight"
      :src="imageSource"
      :srcset="imageSourceSet"
      :sizes="sizes"
      :alt="imageTitle"
    />
    <div class="hover">
      <h3 v-if="category" class="category" v-html="category" />
      <h2 class="title" v-html="title" />
    </div>
  </router-link>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ContentTile',

  props: {
    tile: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState(['media']),
    hasImage() {
      return this.tile.image !== 0
    },
    imageClass() {
      return this.hasImage ? 'has-image' : 'has-no-image'
    },
    title() {
      return this.tile.title
    },
    category() {
      return this.tile.category
    },
    name() {
      return this.tile.name
    },
    image() {
      return this.media.data.find(({ id }) => id === this.tile.image)
    },
    imageSource() {
      return this.image?.src
    },
    imageSourceSet() {
      return this.image?.srcset
    },
    imageWidth() {
      return this.image?.width
    },
    imageHeight() {
      return this.image?.height
    },
    imageTitle() {
      return this.image?.title
    },
    aspectRatio() {
      return this.hasImage ? this.image?.aspectRatio : 0.625
    },
    sizes() {
      return '(min-width: 1400px) 25vw, (min-width: 1000px) 33vw, (min-width: 400px) 50vw, 100vw'
    }
  }
}
</script>

<style lang="scss" scoped>
.content-tile {
  position: relative;
  margin-bottom: var(--margin-bottom, 0.75rem);
  background-color: hsl(0, 0%, 4%);
  display: block;
  color: black;
  overflow: hidden;
  outline: none;
  backface-visibility: hidden;
  opacity: 0.999;
  cursor: pointer;

  &.has-no-image,
  &:hover,
  &:focus {
    --tile-opacity: 1;
    --scale: 1.03;
    --gap: 1.75rem;
    cursor: pointer;
  }

  &.has-image {
    .hover {
      background-color: var(--neon-green);
    }
  }

  &::before {
    display: block;
    padding-top: calc(100% / var(--aspect-ratio, 0.625));
    content: '';
  }

  @media (min-width: 600px) {
    --margin-bottom: 1rem;
  }

  @media (min-width: 1000px) {
    --margin-bottom: 1.25rem;
  }

  @media (min-width: 1400px) {
    --margin-bottom: 2rem;
  }
}

.ueber-mich {
  background-color: var(--beige);
}

.rueckblick {
  background-color: var(--light-blue);
}

img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  object-position: 50% 50%;
  height: 100%;
  width: 100%;
  pointer-events: none;
  transform: scale(1.01);
}

.hover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  display: grid;
  gap: var(--gap, 2rem);
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  opacity: var(--tile-opacity, 0);
  backface-visibility: hidden;
  transform: scale(var(--scale, 1.01)) perspective(1px);
  transition-property: opacity, transform, gap;
  transition-duration: var(--transition-duration),
    calc(var(--transition-duration) * 2), var(--transition-duration);
  transition-timing-function: ease-in, ease-out, ease-in-out;
  padding: 2rem 1rem;
}

.title,
.category {
  backface-visibility: hidden;
}

.title {
  font-size: 1.5rem;
  font-weight: 700;
}

.category {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.85rem;
  letter-spacing: 0.1rem;
}
</style>
