import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    meta: {
      status: undefined,
      data: []
    },
    tiles: {
      status: undefined,
      data: []
    },
    media: {
      status: undefined,
      data: []
    }
  },
  mutations: {
    SET_META(state, meta) {
      state.meta.data = meta
    },
    SET_META_STATUS(state, status) {
      state.meta.status = status
    },
    SET_TILES(state, tiles) {
      state.tiles.data = tiles
    },
    SET_TILES_STATUS(state, status) {
      state.tiles.status = status
    },
    SET_MEDIA(state, media) {
      state.media.data = media
    },
    SET_MEDIA_STATUS(state, status) {
      state.media.status = status
    }
  },
  actions: {
    GET_META({ commit }) {
      commit('SET_META_STATUS', 'loading')
      axios
        .get(`/api`)
        .then(({ data }) => {
          commit('SET_META', data)
          commit('SET_META_STATUS', 'success')
        })
        .catch(error => {
          commit('SET_META_STATUS', 'error')
          console.error(error)
        })
    },
    GET_TILES({ commit }) {
      commit('SET_TILES_STATUS', 'loading')
      axios
        .get('/api/nadiamariella/tiles')
        .then(({ data }) => {
          commit(
            'SET_TILES',
            data.map(values => {
              values.blocks = values.blocks.filter(
                block => block.blockName !== null
              )
              return values
            })
          )
          commit('SET_TILES_STATUS', 'success')
        })
        .catch(error => {
          commit('SET_TILES_STATUS', 'error')
          console.error(error)
        })
    },
    GET_MEDIA({ commit }) {
      commit('SET_MEDIA_STATUS', 'loading')
      axios
        .get('/api/nadiamariella/media')
        .then(({ data }) => {
          commit('SET_MEDIA', data)
          commit('SET_MEDIA_STATUS', 'success')
        })
        .catch(error => {
          commit('SET_MEDIA_STATUS', 'error')
          console.error(error)
        })
    }
  }
})
