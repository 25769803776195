<template>
  <div class="gallery-container">
    <div
      v-if="media.status === 'success'"
      :style="`--gallery-columns:${columns}`"
      :class="[imageCrop ? 'square' : 'original-ratio']"
      class="gallery"
    >
      <block-image
        v-for="(image, imageIndex) in filteredMedia"
        :key="imageIndex"
        :image="image"
        :index="imageIndex"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BlockImage from '@/components/blocks/BlockImage.vue'

export default {
  name: 'BlockGallery',

  components: {
    BlockImage
  },

  props: {
    ids: {
      type: Array,
      required: true
    },
    columns: {
      type: [Number, Boolean],
      required: false,
      default: false
    },
    imageCrop: {
      type: Boolean,
      required: false,
      default: false
    },
    sizeSlug: {
      type: String,
      required: false,
      default: 'full'
    },
    linkTo: {
      type: String,
      required: false,
      default: 'none'
    }
  },

  computed: {
    ...mapState(['media']),
    filteredMedia() {
      return this.media.data
        .filter(({ id }) => this.ids.includes(id))
        .sort((a, b) => {
          return this.ids.indexOf(a.id) > this.ids.indexOf(b.id) ? 1 : -1
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.gallery-container {
  overflow: hidden;

  *,
  & {
    user-select: none;
  }

  @media (max-width: 500px) {
    .gallery {
      grid-template-columns: 100% !important;
      margin: 0;
      padding: 0;
    }
  }
}

.gallery {
  --gallery-columns: auto-fit;

  display: grid;
  grid-gap: var(--gallery-gap, 1rem);
  grid-template-columns: repeat(var(--gallery-columns), 1fr);
  grid-auto-rows: max-content;
  align-items: stretch;
  justify-items: stretch;

  &.square {
    --aspect-ratio: 1;
  }
}
</style>
