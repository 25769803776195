<template>
  <div v-html="list" :is="listTag" />
</template>

<script>
export default {
  name: 'BlockList',

  props: {
    html: {
      type: String,
      required: true
    },
    ordered: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },

  computed: {
    listTag() {
      return this.ordered ? 'ol' : 'ul'
    },
    list() {
      return this.html
        .replaceAll('\n', '')
        .replace(`<${this.listTag}>`, '')
        .replace(`</${this.listTag}>`, '')
    }
  }
}
</script>

<style lang="scss">
.core-list {
  margin-left: 1rem;
  line-height: 1.6;

  ul,
  ol {
    margin-left: 1rem;
  }
}
</style>
