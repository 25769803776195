<template>
  <div class="block-embed">
    <iframe :src="source" allowfullscreen frameborder="0" />
  </div>
</template>

<script>
export default {
  name: 'BlockEmbed',

  props: {
    block: {
      type: Object,
      required: true
    }
  },

  computed: {
    provider() {
      return this.block.attrs.providerNameSlug
    },
    isYouTube() {
      return this.provider === 'youtube'
    },
    isVimeo() {
      return this.provider === 'vimeo'
    },
    url() {
      return this.block.attrs.url
    },
    source() {
      let source
      if (this.isYouTube) {
        const youTubeSource = this.url.includes('youtu.be')
          ? this.url.split('youtu.be/')[1]
          : this.url.split('?v=')[1]
        source = `https://youtube.com/embed/${youTubeSource}?modestbranding=1&rel=0&showinfo=0`
      }
      if (this.isVimeo) {
        const vimeoSource = this.url.split('.com/')[1]
        source = `https://player.vimeo.com/video/${vimeoSource}?byline=0&portrait=0&badge=0&title=0&color=ffffff`
      }
      return source
    }
  }
}
</script>

<style lang="scss" scoped>
.block-embed {
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: calc(100% / (16 / 9));
    background-color: var(--neon-green);
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
