<template>
  <header class="header">
    <span class="portfolio">Portfolio</span>
    <router-link to="/" :class="homeClass" class="home-link">
      <nadia-mariella class="logo" />
    </router-link>
    <router-link
      to="/"
      :class="`${$route.params.name} ${homeClass}`"
      class="close"
    >
      <icon-close />
    </router-link>
  </header>
</template>

<script>
import NadiaMariella from '@/assets/logo.svg?inline'
import IconClose from '@/assets/close.svg?inline'

export default {
  name: 'Header',

  components: {
    NadiaMariella,
    IconClose
  },

  computed: {
    homeClass() {
      return this.$route.name !== 'HomeView' ? 'not-home' : 'is-home'
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: grid;
  align-content: center;
  justify-content: center;
  padding: 1.5rem;
}

.portfolio {
  font-size: 0.75rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  text-align: center;
}

.home-link {
  display: block;
  padding: 0.75rem;
  margin-bottom: -0.75rem;

  svg {
    backface-visibility: hidden;
    transform: scale(var(--scale, 1.001)) perspective(1px);
    transition: var(--transition-duration);
  }

  &:hover {
    --scale: 1.02;
  }

  &:active {
    --scale: 0.98;
  }

  &.is-home {
    --scale: 1.001 !important;
    cursor: default;
  }
}

.logo {
  height: 3.5rem;
}

.close {
  position: fixed;
  top: 0;
  right: 0;
  padding: 2rem;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: calc(var(--transition-duration) * 1.5) var(--transition-duration);
  transform: scale(0.75);

  @media (min-width: 800px) {
    &.not-home {
      pointer-events: all;
      opacity: 1;
      cursor: pointer;
      transform: scale(1);
      --stroke-color: var(--neon-green);
    }

    &:active {
      transition-delay: 0s;
      transition-duration: var(--transition-duration);
      transform: scale(0.75);
    }

    &.ueber-mich {
      --stroke-color: var(--beige);
    }

    &.rueckblick {
      --stroke-color: var(--light-blue);
    }

    svg {
      width: 6.5rem;
      height: 6.5rem;

      path {
        transition: calc(var(--transition-duration) * 3);
        stroke: var(--stroke-color, hsl(0, 0%, 80%));
      }
    }
  }
}
</style>
