import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Page from '@/views/Page.vue'
import VueScrollTo from 'vue-scrollto'

Vue.use(VueRouter)
Vue.use(VueScrollTo)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: Home
  },
  {
    path: '/:name/',
    name: 'PageView',
    component: Page
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    VueScrollTo.scrollTo(document.querySelector('.header'))
  }
})

export default router
