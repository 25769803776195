<template>
  <div id="app" class="app">
    <the-header />
    <transition
      appear
      name="slide"
      mode="out-in"
      @beforeLeave="beforeLeave"
      @enter="enter"
      @afterEnter="afterEnter"
    >
      <router-view :key="$route.fullPath" />
    </transition>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'

export default {
  name: 'NadiaMariella',

  components: {
    TheHeader
  },

  data() {
    return {
      previousHeight: 0
    }
  },

  mounted() {
    this.$store.dispatch('GET_META')
    this.$store.dispatch('GET_TILES')
    this.$store.dispatch('GET_MEDIA')
  },

  methods: {
    beforeLeave(element) {
      this.previousHeight = getComputedStyle(element).height
    },
    enter(element) {
      const { height } = getComputedStyle(element)

      element.style.height = this.previousHeight

      setTimeout(() => {
        element.style.height = height
      })
    },
    afterEnter(element) {
      element.style.height = 'auto'
    }
  }
}
</script>

<style lang="scss">
@import url("//hello.myfonts.net/count/3e78d4");

@font-face {
  font-family: "AlbulaPro";
  src: url('~@/assets/fonts/AlbulaProMedium.woff2') format('woff2'), url('~@/assets/fonts/AlbulaProMedium.woff') format('woff');
  font-weight: 700;
}
@font-face {
  font-family: "AlbulaPro";
  src: url('~@/assets/fonts/AlbulaProRegular.woff2') format('woff2'), url('~@/assets/fonts/AlbulaProRegular.woff') format('woff');
  font-weight: 400;
}

* {
  margin: 0;
  padding: 0;

  &::after,
  &::before {
    margin: 0;
    padding: 0;
  }

  &:not(input):not(textarea) {
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: hsla(0, 0%, 0%, 0);
  }

  &:not(svg):not(g):not(path) {
    box-sizing: border-box;
    transform-origin: 50% 50%;
    transition-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    text-size-adjust: none;
  }

  &:focus {
    outline: 0;
  }
}

::selection {
  background-color: var(--color-primary, black);
  color: white;
}

:root {
  --neon-green: hsl(109, 100%, 69%);
  --beige: hsl(53, 36%, 90%);
  --light-blue: hsl(201, 87%, 85%);

  --transition-duration: 400ms;
  --max-width: 140ch;

  font-size: clamp(16px, 1.25vw, 22px);
  font-family: 'AlbulaPro', sans-serif;
  font-weight: 200;
}

:root,
body {
  display: grid;
  align-items: baseline;
  max-width: 100%;
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  background-color: white;
  overflow-anchor: none;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-snap-type: y proximity;
  scroll-snap-stop: normal;
}

.app {
  display: grid;
  min-height: 100%;
  align-content: start;
}

.slide-enter-active,
.slide-leave-active {
  transition-duration: calc(var(--transition-duration) * 2.5);
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-enter-active {
  transition-duration: var(--transition-duration);
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}

a {
  color: black;
  font-weight: 400;
  transition: 200ms;

  &:hover {
    color: var(--neon-green);
  }
}
</style>
