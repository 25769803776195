<template>
  <article
    v-if="tiles.status === 'success' && page"
    :class="page.name"
    class="content page"
  >
    <content-blocks :key="`content-blocks-${page.id}`" :blocks="page.blocks" />
  </article>
</template>

<script>
import { mapState } from 'vuex'
import ContentBlocks from '@/components/ContentBlocks.vue'

export default {
  name: 'Page',

  components: {
    ContentBlocks
  },

  computed: {
    ...mapState(['tiles']),

    page() {
      return this.tiles.data.find(page => page.name === this.$route.params.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 2rem;
  display: flex;
  justify-content: center;
}
</style>
