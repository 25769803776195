<template>
  <masonry
    v-if="media.status === 'success' && tiles.status === 'success'"
    :cols="columns"
    :gutter="gutter"
    class="home"
  >
    <content-tile
      v-for="(tile, tileIndex) in tiles.data"
      :key="`content-tile-${tileIndex}`"
      :tile="tile"
      :data-aos-delay="50 * tileIndex"
      data-aos="fade-up"
    />
  </masonry>
</template>

<script>
import { mapState } from 'vuex'
import ContentTile from '@/components/ContentTile.vue'

export default {
  name: 'Home',

  components: {
    ContentTile
  },

  computed: {
    ...mapState(['media', 'tiles']),
    columns() {
      return {
        default: 4,
        1400: 3,
        1000: 2,
        400: 1
      }
    },
    gutter() {
      return {
        default: '2rem',
        1400: '1.25rem',
        1000: '1rem',
        600: '0.75rem'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  padding-left: var(--padding, 0.75rem);
  padding-right: var(--padding, 0.75rem);
  padding-top: 1rem;

  &::v-deep {
    & > div:nth-of-type(1) {
      transform: translateY(1.15rem);
    }

    & > div:nth-of-type(2) {
      transform: translateY(-0.25rem);
    }

    & > div:nth-of-type(3) {
      transform: translateY(0.15rem);
    }

    & > div:nth-of-type(4) {
      transform: translateY(1.15rem);
    }
  }

  @media (min-width: 600px) {
    --padding: 1rem;
  }

  @media (min-width: 1000px) {
    --padding: 1.25rem;
  }

  @media (min-width: 1400px) {
    --padding: 2rem;
  }
}
</style>
