<template>
  <div class="blocks">
    <template v-for="(block, blockIndex) in blocks">
      <template v-if="block.blockName === 'core/embed'">
        <block-embed
          :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
          :class="`${block.attrs.className} ${block.attrs.providerNameSlug}`"
          :block="block"
          :data-aos-delay="50 * blockIndex"
          data-aos="fade-up"
        />
      </template>
      <template v-else-if="block.blockName === 'core/gallery'">
        <block-gallery
          :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
          :ids="block.attrs.ids"
          :columns="block.attrs.columns"
          :image-crop="block.attrs.imageCrop"
          :data-aos-delay="50 * blockIndex"
          data-aos="fade-up"
        />
      </template>
      <template v-else-if="block.blockName === 'core/image'">
        <block-image
          :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
          :id="block.attrs.id"
          :size="block.attrs.sizeSlug"
          :data-aos-delay="50 * blockIndex"
          data-aos="fade-up"
        />
      </template>
      <template v-else-if="block.blockName === 'core/heading'">
        <block-heading
          :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
          :class="block.blockName.replace('/', '-')"
          :level="block.attrs.level"
          :html="block.innerHTML"
          :data-aos-delay="50 * blockIndex"
          data-aos="fade-up"
        />
      </template>
      <template v-else-if="block.blockName === 'core/paragraph'">
        <block-paragraph
          :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
          :class="block.blockName.replace('/', '-')"
          :html="block.innerHTML"
          :data-aos-delay="50 * blockIndex"
          data-aos="fade-up"
        />
      </template>
      <template v-else-if="block.blockName === 'core/list'">
        <block-list
          :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
          :class="block.blockName.replace('/', '-')"
          :html="block.innerHTML"
          :ordered="block.attrs.ordered"
          :data-aos-delay="50 * blockIndex"
          data-aos="fade-up"
        />
      </template>
      <template v-else>
        <block-generic
          :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
          :class="block.blockName.replace('/', '-')"
          :html="block.innerHTML"
          :data-aos-delay="50 * blockIndex"
          data-aos="fade-up"
        />
      </template>
    </template>
  </div>
</template>

<script>
import BlockEmbed from '@/components/blocks/BlockEmbed.vue'
import BlockGallery from '@/components/blocks/BlockGallery.vue'
import BlockImage from '@/components/blocks/BlockImage.vue'
import BlockHeading from '@/components/blocks/BlockHeading.vue'
import BlockParagraph from '@/components/blocks/BlockParagraph.vue'
import BlockList from '@/components/blocks/BlockList.vue'
import BlockGeneric from '@/components/blocks/BlockGeneric.vue'

export default {
  name: 'ContentBlocks',

  components: {
    BlockEmbed,
    BlockGallery,
    BlockImage,
    BlockHeading,
    BlockParagraph,
    BlockList,
    BlockGeneric
  },

  props: {
    blocks: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
.blocks > * + * {
  margin-top: 1.25rem;
}

.blocks {
  flex: 0 1 var(--max-width, 140ch);

  > :last-child {
    margin-bottom: 35vh;
  }
}

.has-small-font-size {
  font-size: 0.8rem;
}

.has-normal-font-size {
  font-size: 1rem;
}

.has-medium-font-size {
  font-size: 1.35rem;
}

.has-large-font-size {
  font-size: 2.5rem;
}

.has-huge-font-size {
  font-size: 4rem;
}

.has-text-align-right {
  text-align: right;
}

.has-text-align-left {
  text-align: left;
}

.has-text-align-center {
  text-align: center;
}

.has-undefined-background-color {
  background-color: var(--background-color);
}

.has-pale-pink-background-color {
  background-color: #f78da7;
}

.has-vivid-red-background-color {
  background-color: #cf2e2e;
}

.has-luminous-vivid-orange-background-color {
  background-color: #ff6900;
}

.has-luminous-vivid-amber-background-color {
  background-color: #fcb900;
}

.has-light-green-cyan-background-color {
  background-color: #7bdcb5;
}

.has-vivid-green-cyan-background-color {
  background-color: #00d084;
}

.has-pale-cyan-blue-background-color {
  background-color: #8ed1fc;
}

.has-vivid-cyan-blue-background-color {
  background-color: #0693e3;
}

.has-vivid-purple-background-color {
  background-color: #9b51e0;
}

.has-very-light-gray-background-color {
  background-color: #eee;
}

.has-cyan-bluish-gray-background-color {
  background-color: #abb8c3;
}

.has-very-dark-gray-background-color {
  background-color: #313131;
}

.has-pale-pink-color {
  color: #f78da7;
}

.has-vivid-red-color {
  color: #cf2e2e;
}

.has-luminous-vivid-orange-color {
  color: #ff6900;
}

.has-luminous-vivid-amber-color {
  color: #fcb900;
}

.has-light-green-cyan-color {
  color: #7bdcb5;
}

.has-vivid-green-cyan-color {
  color: #00d084;
}

.has-pale-cyan-blue-color {
  color: #8ed1fc;
}

.has-vivid-cyan-blue-color {
  color: #0693e3;
}

.has-vivid-purple-color {
  color: #9b51e0;
}

.has-very-light-gray-color {
  color: #eee;
}

.has-cyan-bluish-gray-color {
  color: #abb8c3;
}

.has-very-dark-gray-color {
  color: #313131;
}

.has-undefined-color {
  color: var(--text-color);
}

.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
  background: linear-gradient(
    135deg,
    rgba(6, 147, 227, 1) 0%,
    rgb(155, 81, 224) 100%
  );
}

.has-vivid-green-cyan-to-vivid-cyan-blue-gradient-background {
  background: linear-gradient(
    135deg,
    rgba(0, 208, 132, 1) 0%,
    rgba(6, 147, 227, 1) 100%
  );
}

.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
  background: linear-gradient(
    135deg,
    rgb(122, 220, 180) 0%,
    rgb(0, 208, 130) 100%
  );
}

.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
  background: linear-gradient(
    135deg,
    rgba(252, 185, 0, 1) 0%,
    rgba(255, 105, 0, 1) 100%
  );
}

.has-luminous-vivid-orange-to-vivid-red-gradient-background {
  background: linear-gradient(
    135deg,
    rgba(255, 105, 0, 1) 0%,
    rgb(207, 46, 46) 100%
  );
}

.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
  background: linear-gradient(
    135deg,
    rgb(238, 238, 238) 0%,
    rgb(169, 184, 195) 100%
  );
}

.has-cool-to-warm-spectrum-gradient-background {
  background: linear-gradient(
    135deg,
    rgb(74, 234, 220) 0%,
    rgb(151, 120, 209) 20%,
    rgb(207, 42, 186) 40%,
    rgb(238, 44, 130) 60%,
    rgb(251, 105, 98) 80%,
    rgb(254, 248, 76) 100%
  );
}

.has-blush-light-purple-gradient-background {
  background: linear-gradient(
    135deg,
    rgb(255, 206, 236) 0%,
    rgb(152, 150, 240) 100%
  );
}

.has-blush-bordeaux-gradient-background {
  background: linear-gradient(
    135deg,
    rgb(254, 205, 165) 0%,
    rgb(254, 45, 45) 50%,
    rgb(107, 0, 62) 100%
  );
}

.has-purple-crush-gradient-background {
  background: linear-gradient(
    135deg,
    rgb(52, 226, 228) 0%,
    rgb(71, 33, 251) 50%,
    rgb(171, 29, 254) 100%
  );
}

.has-luminous-dusk-gradient-background {
  background: linear-gradient(
    135deg,
    rgb(255, 203, 112) 0%,
    rgb(199, 81, 192) 50%,
    rgb(65, 88, 208) 100%
  );
}

.has-hazy-dawn-gradient-background {
  background: linear-gradient(
    135deg,
    rgb(250, 172, 168) 0%,
    rgb(218, 208, 236) 100%
  );
}

.has-pale-ocean-gradient-background {
  background: linear-gradient(
    135deg,
    rgb(255, 245, 203) 0%,
    rgb(182, 227, 212) 50%,
    rgb(51, 167, 181) 100%
  );
}

.has-electric-grass-gradient-background {
  background: linear-gradient(
    135deg,
    rgb(202, 248, 128) 0%,
    rgb(113, 206, 126) 100%
  );
}

.has-subdued-olive-gradient-background {
  background: linear-gradient(
    135deg,
    rgb(250, 250, 225) 0%,
    rgb(103, 166, 113) 100%
  );
}

.has-atomic-cream-gradient-background {
  background: linear-gradient(
    135deg,
    rgb(253, 215, 154) 0%,
    rgb(0, 74, 89) 100%
  );
}

.has-nightshade-gradient-background {
  background: linear-gradient(
    135deg,
    rgb(51, 9, 104) 0%,
    rgb(49, 205, 207) 100%
  );
}

.has-midnight-gradient-background {
  background: linear-gradient(
    135deg,
    rgb(2, 3, 129) 0%,
    rgb(40, 116, 252) 100%
  );
}
</style>
