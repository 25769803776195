<template>
  <p v-html="paragraph" />
</template>

<script>
export default {
  name: 'BlockParagraph',

  props: {
    html: {
      type: String,
      required: true
    }
  },

  computed: {
    paragraph() {
      return this.html
        .replaceAll('\n', '')
        .replace('<p>', '')
        .replace('</p>', '')
    }
  }
}
</script>

<style lang="scss">
.core-paragraph {
  hyphens: auto;
  user-select: text;
}
</style>
