<template>
  <h1 v-html="heading" :is="`h${level}`" />
</template>

<script>
export default {
  name: 'BlockHeading',

  props: {
    level: {
      type: Number,
      required: true
    },
    html: {
      type: String,
      required: true
    }
  },

  computed: {
    heading() {
      return this.html
        .replaceAll('\n', '')
        .replace(`<h${this.level}>`, '')
        .replace(`</h${this.level}>`, '')
    }
  }
}
</script>

<style lang="scss">
.core-heading {
  hyphens: auto;
  font-weight: 400;
  user-select: text;
}
</style>
