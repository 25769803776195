<template>
  <figure
    v-if="media.status === 'success'"
    :class="captionClass"
    class="image-container"
    data-aos="fade-up"
    data-aos-easing="ease-in-out-quart"
  >
    <div
      class="aspect-ratio"
      :class="size"
      :style="
        `
        --aspect-ratio:${imageObject.width / imageObject.height};
        --height:${imageObject.height}px;
        --width:${imageObject.width}px;
      `
      "
    >
      <img
        :height="imageObject.height"
        :width="imageObject.width"
        :src="imageObject.src"
        :tabindex="index"
        class="image"
      />
    </div>
    <figcaption v-if="imageObject.caption" v-html="imageObject.caption" />
  </figure>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BlockImage',

  props: {
    image: {
      type: [Object, Boolean],
      required: false,
      default: () => false
    },
    index: {
      type: Number,
      required: false,
      default: () => 1
    },
    id: {
      type: [Number, Boolean],
      required: false,
      default: () => false
    },
    size: {
      type: String,
      required: false,
      default: () => 'full'
    }
  },

  computed: {
    ...mapState(['media']),

    imageObject() {
      return this.image
        ? this.image
        : this.media.data.find(({ id }) => id === this.id)
    },

    captionClass() {
      return this.imageObject.caption.length ? 'has-caption' : 'no-caption'
    }
  }
}
</script>

<style lang="scss">
.image-container {
  &.has-caption {
    display: grid;
    grid-template-areas: 'image' 'caption';
    grid-template-columns: 1fr;
  }

  .aspect-ratio {
    max-height: calc(var(--height) * var(--multiplier, 1));
    max-width: calc(var(--width) * var(--multiplier, 1));
    position: relative;

    &.thumbnail {
      --multiplier: 0.2;
      --aspect-ratio: 1 !important;
      --object-fit: cover;
    }

    &.medium {
      --multiplier: 0.4;
    }

    &.large {
      --multiplier: 0.8;
    }

    &::before {
      padding-top: calc(100% / var(--aspect-ratio, 1));
      display: block;
      content: '';
    }
  }

  img {
    border-radius: var(--border-radius);
    transition: calc(var(--transition-duration) * 0.75);
    transition-timing-function: ease-out;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: var(--object-fit, scale-down);
    object-position: 50% 0;
    backface-visibility: hidden;
  }

  figcaption {
    margin-top: 1rem;
  }
}
</style>
